{
  "LANGUAGE_NAME": "English",
  "MENU": {
    "HOME": "Home",
    "TELEPHONE": "Telephone",
    "CHAT": "Chat",
    "SETTINGS": "Settings"
  },
  "PAGES": {
    "INFO_PAGE": "Information Center",
    "GOOD_HABITS": "Good Habits"
  },
  "ASK_SUPPORT": {
    "CONTACT_TEXT": "Call our counselors",
    "CHAT_TEXT": "Chat with our counselors"
  },
  "NOTIFICATIONS": {
    "TITLE": "Notifications",
    "SELECT_FREQUENCY": "Select the frequency",
    "FREQ_NEVER": "Never",
    "FREQ_DAILY": "Daily",
    "FREQ_CHOICE": "Choose your days",
    "DAYS": "Days",
    "TIME": "Time"
  },
  "GOOD_HABITS": {
    "INTRO": "Which good habits would you like to incorporate into your daily life?",
    "ADD_YOUR_HABIT_TEXT": "Highlight your favorites with a star",
    "NEW": "New",
    "YOU_ARE_ABOUT_TO_REMOVE_A_HABBIT_FROM_FAVORITES": "Do you want to remove this habit from your favorites?",
    "YOU_DONT_HAVE_ANY_FAVORITES_YET": "You have not selected favorites yet",
    "GOOD_HABIT_1_TITLE": "Enjoy some fresh air",
    "GOOD_HABIT_1_TEXT": "Go for a short walk",
    "GOOD_HABIT_2_TITLE": "Find the rhythm",
    "GOOD_HABIT_2_TEXT": "Dance - together or alone",
    "GOOD_HABIT_3_TITLE": "Lyrical pleasure",
    "GOOD_HABIT_3_TEXT": "read a book every day/every week",
    "GOOD_HABIT_4_TITLE": "Disconnect",
    "GOOD_HABIT_4_TEXT": "Let your mobile phone stay outside your bedroom",
    "GOOD_HABIT_5_TITLE": "Spread joy",
    "GOOD_HABIT_5_TEXT": "Smile at a stranger",
    "GOOD_HABIT_6_TITLE": "Music",
    "GOOD_HABIT_6_TEXT": "Take time to liste to my favorite musician",
    "GOOD_HABIT_7_TITLE": "Pass your day in review",
    "GOOD_HABIT_7_TEXT": "Think of the good moments of your day and be grateful for them",
    "GOOD_HABIT_8_TITLE": "Flower power",
    "GOOD_HABIT_8_TEXT": "Buy flowers for the weekend",
    "GOOD_HABIT_9_TITLE": "Slow down",
    "GOOD_HABIT_9_TEXT": "Take some extra time and enjoy",
    "GOOD_HABIT_10_TITLE": "Enjoy some fresh air",
    "GOOD_HABIT_10_TEXT": "Spend some time in nature",
    "GOOD_HABIT_11_TITLE": "Stairs",
    "GOOD_HABIT_11_TEXT": "More stairs, less elevators",
    "GOOD_HABIT_12_TITLE": "Feel the ground",
    "GOOD_HABIT_12_TEXT": "Walk barefoot",
    "GOOD_HABIT_13_TITLE": "Spread joy",
    "GOOD_HABIT_13_TEXT": "Say what I like",
    "GOOD_HABIT_14_TITLE": "The way to the heart is through the stomach",
    "GOOD_HABIT_14_TEXT": "Cook with others",
    "GOOD_HABIT_15_TITLE": "Joys of playing",
    "GOOD_HABIT_15_TEXT": "Play a game again",
    "GOOD_HABIT_16_TITLE": "Check out tastes",
    "GOOD_HABIT_16_TEXT": "Trying new tea varieties",
    "GOOD_HABIT_17_TITLE": "Freshly covered",
    "GOOD_HABIT_17_TEXT": "Wash your bed linen, hang it up outside, sleep in it in the evening - wonderful!",
    "GOOD_HABIT_18_TITLE": "Do good",
    "GOOD_HABIT_18_TEXT": "Do a good deed everyday",
    "GOOD_HABIT_19_TITLE": "Close to the toes",
    "GOOD_HABIT_19_TEXT": "Stretching or yoga in the morning",
    "GOOD_HABIT_20_TITLE": "Move",
    "GOOD_HABIT_20_TEXT": "Walk around the block every day",
    "GOOD_HABIT_21_TITLE": "Test out tastes",
    "GOOD_HABIT_21_TEXT": "Mindful eating: How does taste unfold?",
    "GOOD_HABIT_22_TITLE": "Digital detox",
    "GOOD_HABIT_22_TEXT": "Create time-outs from electronics",
    "GOOD_HABIT_23_TITLE": "Feel beautiful",
    "GOOD_HABIT_23_TEXT": "Wear beautiful clothes",
    "GOOD_HABIT_24_TITLE": "Clean out",
    "GOOD_HABIT_24_TEXT": "Eliminate the unnecessary in everyday life",
    "GOOD_HABIT_25_TITLE": "Enjoy art",
    "GOOD_HABIT_25_TEXT": "Visit a concert or museum online"
  },
  "LOGIN": {
    "NEXT": "NEXT",
    "INTRO_TEXT": "Code",
    "NAME_FIELD": "User Name",
    "REQUIRED_FIELD": "Required",
    "NAME_OR_PSEUDONYM_REQUIRED": "Please sign up with a username.",
    "CODE_NOT_CORRECT": "The entered code is incorrect.",
    "AGE_GROUP": "Age",
    "YEARS_OLD": "years old",
    "I_AGREE_TERMS": "I agree with {{terms}}",
    "TERMS_TEXT": "the terms of use",
    "LOGIN_CONSENT_LINK_TEXT": "the privacy policy",
    "LOGIN_CONSENT": "By logging in you consent to the processing of data according to {{data}}.",
    "LOGIN": "Login"
  },
  "HOME": {
    "CODE_INVALID_TEXT": "Your session was closed because your code expired.",
    "BACKGROUND_ARTICLES": "Articles",
    "BACKGROUND_ARTICLES_SUB": "Information and Inspiration",
    "GOOD_HABITS": "Good Habits",
    "REFLECTION": "Reflection",
    "GOALS": "Goals",
    "WHAT_IS_EAP": "How we can support you",
    "QUICK_CHECKLISTS": "Checklists",
    "QUICK_CHAT": "Chat",
    "QUICK_TELEPHONE": "Telephone",
    "QUICK_RELAX": "Relax",
    "QUICK_PODCASTS": "Podcasts",
    "QUICK_VIDEOS": "Videos",
    "QUICK_APPOINTMENTS": "Appointment",
    "TOPICAL": "News",
    "ARTICLE_OVERVIEW": "Overview",
    "ARTICLE_OVERVIEW_SUBTITLE": "All ",
    "CONTACT": "Contact",
    "UNWANTED_BEHAVIOR": "Unwanted Behavior",
    "SHOW_ALL_CATEGORIES": "View all Articles",
    "ARTICLE_OF_THE_WEEK": "Article of the Week",
    "TESTS": "Tests"
  },
  "INFO_PAGES": {
    "THEMES_INFO": "You can find information and inspiration here.",
    "OTHER_THEMES": "Other Topics",
    "NEED_SUPPORT": "Can we help you?",
    "ARTICLES": "Articles"
  },
  "INFO_PAGE": {
    "TITLE": "Information Center",
    "READING_TIME": "{{minutes}} min. Reading Time",
    "READING_TIME_ALT": "Reading Time: {{minutes}} min."
  },
  "CONTACT": {
    "TITLE": "Telephone",
    "CONTENT": "Call us when you want to talk to somebody. In case of emergency we are available 24/7.",
    "CALL": "Phone Call",
    "CALL_DESCRIPTION": "Contact our counselors",
    "TELEPHONE": "Telephone",
    "TELEPHONE_SAVE_OR_SHARE": "Phone Number *",
    "TELEPHONE_NUMBER_1": "0800 3212345",
    "TELEPHONE_NUMBER_1_ADDITION": "(Germany)",
    "TELEPHONE_NUMBER_2": "00800 32123456",
    "TELEPHONE_NUMBER_2_ADDITION": "(Austria)",
    "TELEPHONE_NUMBER_3_TITLE": "from Abroad **",
    "TELEPHONE_NUMBER_3": "+49 431 895 19 32",
    "TELEPHONE_NUMBER_3_ADDITION": "",
    "ADDITIONAL_INFO_1": "* free of charge",
    "ADDITIONAL_INFO_2": "** fees may apply",
    "CHAT": "Chat"
  },
  "SETTINGS": {
    "TITLE": "Settings",
    "LANGUAGE": "Language",
    "NOTIFICATIONS": "Notifications - Reflection",
    "FEEDBACK": "Feedback on the app",
    "PRIVACY_POLICY": "Privacy Policy",
    "USER_CONDITIONS": "Terms of Use",
    "ABOUT_OS_LICENSES": "About / Open Source Licenses"
  },
  "CHAT": {
    "CHAT_CONSENT": "In case of emergency dial 112 or contact the counseling team by phone.",
    "I_UNDERSTAND": "I understand",
    "DECLINE": "Decline",
    "CONSULTANT_TEAM": "Our Counselors",
    "CHAT_INTRO": "Send us a message so that we can support you with your request.",
    "USER_HAS_UNSIGNED_CHATS": "Our chat is not live at the moment. Please leave a message, our counselors will contact you within the next working day. In case of emergency, contact us on the phone or dial 112.",
    "NOT_ASSIGNED": "Counselor on duty",
    "NEW_CHAT": "New Chat",
    "INPUT_PLACEHOLDER": "Type...",
    "INPUT_PLACEHOLDER_CONNECTING": "Setting up a secure connection...",
    "CURRENT_CHAT": "Current Conversation",
    "YOU_ARE_SPEAKING_WITH": "You are talking to "
  },
  "AUDIO_PLAYER": {
    "DOWNLOADING": "Downloading"
  },
  "FEEDBACK": {
    "TITLE": "Feedback on the app",
    "INPUT_PLACEHOLDER": "Type...",
    "INTRO": "We continuously improve our app for you. If you have any feedback regarding the app, you can submit it here. This form is not meant to share personal information.",
    "NAME": "Name",
    "NAME_REQUIRED": "Please enter your name",
    "EMAIL": "e-mail address",
    "EMAIL_INVALID": "Please enter a valid e-mail address",
    "EMAIL_REQUIRED": "Please enter an e-mail address",
    "MESSAGE": "Message",
    "MESSAGE_REQUIRED": "Please enter a message",
    "SUBMIT_SUCCESS": "Your feedback has been sent",
    "SEND": "Send"
  },
  "REFLECTION_INTRO": {
    "TITLE": "How are you?",
    "INTRO_TITLE": "Reflection",
    "INTRO_TEXT": "Here you can keep a diary about how you feel, what costs you energy and what gives you energy."
  },
  "REFLECTION": {
    "TITLE": "How are you?",
    "TITLE_WORK": "At Work",
    "TITLE_SPARE_TIME": "Leisure Time",
    "TITLE_INSIGHTS": "Insights",
    "TITLE_REMINDERS": "Reminders",
    "TITLE_SETTINGS": "Reflection settings",
    "HOW_DO_YOU_FEEL_TODAY": "",
    "SELECT_YOUR_MOOD": "How do you feel today?",
    "DESCRIBE_YOUR_FEELINGS": " ",
    "FEELINGS_WORK": "Which factors at work affect your energy level?",
    "FEELINGS_SPARE_TIME": "Which factors in your spare time affect your energy level?",
    "ADVICE": "Advice",
    "FEELINGS_INSIGHTS": "Feelings",
    "WORK_INSIGHTS": "",
    "SPARE_TIME_INSIGHTS": "",
    "SHARE_TITLE": "Share your reflection",
    "SHARE_TEXT": "Do you want consultation? Share your results with our counselors and develop new energizing strategies.",
    "SHARE_BUTTON": "Share ",
    "SHARE_PRIVACY_INFO": "Privacy Policy",
    "SETTINGS_INTRO": "Create more inner peace with more knowledge of how you feel about things.",
    "SETTINGS_INFO_TITLE": "Learn more about yourself!",
    "SETTINGS_INFO_TEXT": "Keep track of how you feel throughout a longer period of time.",
    "SETTINGS_REMINDER_TITLE": "Would you like to set a reminder for regular reflection?",
    "SETTINGS_REMINDER_FREQUENCY": "Frequency",
    "SETTINGS_REMINDER_DAYS": "Days",
    "SETTINGS_REMINDER_TIME_OF_DAY": "Time of the day",
    "SETTINGS_REMINDER_SELECT_TIME": "Select time",
    "SETTINGS_REMINDER_FREQUENCY_OFF": "Never",
    "SETTINGS_REMINDER_FREQUENCY_DAILY": "Daily",
    "SETTINGS_REMINDER_FREQUENCY_WEEKLY": "Weekly",
    "SETTINGS_REMINDER_FREQUENCY_TWICE_A_WEEK": "Twice a week",
    "SETTINGS_REMINDER_FREQUENCY_MONTHLY": "Monthly",
    "SETTINGS_REMINDER_FREQUENCY_TWICE_A_MONTH": "Twice a month",
    "SETTINGS_SAVE": "Save my settings",
    "FEELINGS_TOP": "Top {{NUM}} feelings",
    "WORK_TOP": "Top {{NUM}} energy at work",
    "SPARE_TIME_TOP": "Top {{NUM}} energy during spare time",
    "POSITIVE": "Positive",
    "NEGATIVE": "Negative",
    "ENERGY_TAKERS": "Energy takers",
    "ENERGY_GIVERS": "Energy givers",
    "ADD_WORD": "Add word"
  },
  "INSIGHTS": {
    "PAGE_TITLE": "Insights",
    "TITLE": "By using this tool you can get more insight in how you feel about things at work and in your private life."
  },
  "INSIGHTS_SHARE": {
    "TITLE": "Share your insights with our counselors, if you wish to talk about your results or if you want to bring change into your daily life.",
    "TEXT": "",
    "MESSAGE_PLACEHOLDER": "How can we help you?",
    "SEND_TO_COUNSELOR": "Send",
    "REFLECTION": "Reflection",
    "LAST_WEEK": "Last week",
    "LAST_MONTH": "Last month"
  },
  "MOODS": {
    "VERY_BAD": "Very bad",
    "BAD": "Bad",
    "MODERATE": "Moderate",
    "GOOD": "Good",
    "VERY_GOOD": "Very good"
  },
  "DAYS": {
    "MONDAY": "Monday",
    "TUESDAY": "Tuesday",
    "WEDNESDAY": "Wednesday",
    "THURSDAY": "Thursday",
    "FRIDAY": "Friday",
    "SATURDAY": "Saturday",
    "SUNDAY": "Sunday"
  },
  "LOADING": "Loading...",
  "BACK": "Back",
  "POSSIBLY_NO_INTERNET_AVAILABLE": "A connection to the server could not be established. Check your internet connection or try again later.",
  "NAME_OR_PSEUDONYM": "Username",
  "GENDER": "Sex",
  "GENDER_MALE_CHAR": "m",
  "GENDER_FEMALE_CHAR": "f",
  "GENDER_DIVERSE_CHAR": "x",
  "GENDER_MALE": "Male",
  "GENDER_FEMALE": "Female",
  "GENDER_DIVERSE": "Other",
  "BUTTON_OK": "OK",
  "BUTTON_SAVE": "Save",
  "BUTTON_DONE": "Done",
  "BUTTON_CANCEL": "Cancel",
  "BUTTON_CLOSE": "Close",
  "BUTTON_PREVIOUS": "Previous",
  "BUTTON_NEXT": "Next",
  "BUTTON_ADD": "add",
  "BUTTON_NEW_WORD": "new word",
  "ARE_YOU_SURE": "Are you sure?",
  "LOGOUT": "Delete all data",
  "LOGOUT_TEXT": "By logging out, your data saved in the app will be deleted.",
  "SETTINGS_SAVED": "Your settings have been saved",
  "OPEN": "Open",
  "FEELINGS_POS": {
    "OPTIMISTIC": "optimistic",
    "TRUSTING": "trusting",
    "PEACEFUL": "peaceful",
    "POWERFUL": "powerful",
    "ACCEPTED": "accepted",
    "PROUD": "proud",
    "CONTENT": "content",
    "PLAYFUL": "playful",
    "ENERGETIC": "energetic",
    "CONFIDENT": "confident",
    "JOYFUL": "joyful",
    "PASSIONATE": "passionate",
    "LOVED": "loved",
    "EAGER": "eager",
    "RELAXED": "relaxed",
    "MOTIVATED": "motivated",
    "HAPPY": "happy",
    "GRATEFUL": "grateful",
    "INSPIRED": "inspired",
    "EXCITED": "excited",
    "NOTICED": "noticed",
    "MINDFUL": "mindful",
    "CALM": "calm",
    "RELIEVED": "relieved"
  },
  "FEELINGS_NEG": {
    "STRESSED": "stressed",
    "TIRED": "tired",
    "CONFUSED": "confused",
    "INSECURE": "insecure",
    "FRUSTRATED": "frustrated",
    "CRITICAL": "critical",
    "DISAPPOINTED": "disappointed",
    "HURT": "hurt",
    "GUILTY": "guilty",
    "DEPRESSED": "depressed",
    "LONELY": "lonely",
    "WORRIED": "worried",
    "EMBARRASSED": "embarrassed",
    "AFRAID": "afraid",
    "SAD": "sad",
    "ANXIOUS": "anxious",
    "UNHAPPY": "unhappy",
    "BORED": "bored",
    "EXHAUSTED": "exhausted",
    "PANICKED": "panicked",
    "ANGRY": "angry",
    "ANNOYED": "annoyed",
    "ASHAMED": "ashamed",
    "JEALOUS": "jealous"
  },
  "WORK_POS": {
    "TEAMWORK": "teamwork",
    "GETTING_COMPLIMENTS": "getting compliments",
    "COFFEE_WITH_COLLEAGUES": "coffee with colleagues",
    "SUPPORT_BY_MANAGER": "support by manager",
    "SUPPORT_BY_COLLEAGUES": "support by colleagues",
    "ACKNOWLEDGMENT": "acknowledgment",
    "HAVING_RESPONSIBILITY": "having responsibility",
    "JOB_SECURITY": "job security",
    "LIVELY_DISCUSSIONS": "lively discussions",
    "A_FULL_SCHEDULE": "a full schedule",
    "CONTACT_WITH_CLIENTS": "contact with clients",
    "ENERGETIC_MEETINGS": "energetic meetings",
    "SOLVING_PROBLEMS": "solving problems",
    "FINDING_CREATIVE_SOLUTIONS": "finding creative solutions",
    "GIVING_PRESENTATIONS": "giving presentations",
    "LISTENING_TO_PRESENTATIONS": "listening to presentations",
    "DELIVERING_QUALITY": "delivering quality",
    "PREVENTING_ERRORS": "preventing errors",
    "STRUCTURED_WORK": "structured work",
    "WORKING_ACCORDING_TO_PLAN": "working according to plan",
    "HAVING_OVERVIEW": "having overview",
    "CELEBRATING_SUCCESS": "celebrating success",
    "A_SHORT_BREAK_OUTSIDE": "a short break outside"
  },
  "WORK_NEG": {
    "TOUGH_MEETINGS": "tough meetings",
    "BORING_PRESENTATIONS": "boring presentations",
    "TOUGH_COMMUNICATION": "tough communication",
    "GOAL_UNCLARITY": "goal unclarity",
    "TIME_PRESSURE": "time pressure",
    "OVERTIME": "overtime",
    "FEAR_OF_FAILURE": "fear of failure",
    "DISRUPTION": "disruption",
    "LACK_OF_CONTROL": "lack of control",
    "MUCH_RESPONSIBILITY": "much responsibility",
    "JOB_INSECURITY": "job insecurity",
    "SHIFT_WORK": "shift work",
    "REMOTEWORK": "remote work",
    "NOISE": "noise",
    "DISCUSSIONS": "discussions",
    "MULTITASKING": "multitasking",
    "CONFLICTS": "conflicts",
    "ILLNESS": "illness",
    "BEING_UNINFORMED": "being uninformed",
    "TRAFFIC_JAM": "traffic jam",
    "HUSTLE": "hustle",
    "HOMESICKNESS": "homesickness",
    "BOREDOM": "boredom",
    "LACK_OF_APPRECIATION": "lack of appreciation"
  },
  "SPARE_TIME_POS": {
    "MEDITATION": "meditation",
    "PLAYING_GAMES": "playing games",
    "BEING_ALONE": "being alone",
    "READING": "reading",
    "COOKING": "cooking",
    "PLAYING_MUSIC": "playing music",
    "SPORTS": "sports",
    "SHOPPING": "shopping",
    "ACKNOWLEDGMENT": "acknowledgment",
    "DISCUSSIONS": "discussions",
    "LOAFING": "loafing",
    "PRIVACY": "privacy",
    "MEETING_FRIENDS": "meeting friends",
    "MEETING_FAMILY": "meeting family",
    "GOING_OUTSIDE": "going outside",
    "PLAYING_WITH_THE_KIDS": "playing with the kids",
    "WATCHING_TV": "watching tv",
    "READING_A_BOOK": "reading a book",
    "GARDENING": "gardening",
    "A_SHORT_WALK": "a short walk",
    "FEELING_THE_WIND": "feeling the wind",
    "FEELING_THE_SUN": "feeling the sun",
    "GOING_TO_A_CONCERT": "going to a concert",
    "FESTIVALS": "festivals",
    "THEATER": "theatre"
  },
  "SPARE_TIME_NEG": {
    "FRIENDS": "friends",
    "FAMILY": "family",
    "BEING_ALONE": "being alone",
    "SLEEP_DEPRIVATION": "sleep deprivation",
    "COOKING": "cooking",
    "MOVEMENT": "movement",
    "GROCERY_SHOPPING": "grocery shopping",
    "NOISE": "noise",
    "DISCUSSIONS": "discussions",
    "MULTITASKING": "multitasking",
    "CONFLICTS": "conflicts",
    "ILLNESS": "illness",
    "PERFECTIONISM": "perfectionism",
    "CONCERNS": "concerns",
    "TRAFFIC_JAM": "traffic jam",
    "BEING_UNINFORMED": "being uninformed",
    "SELF_DOUBT": "self-doubt",
    "DEBT": "debt",
    "HUSTLE": "hustle",
    "HOMESICKNESS": "homesickness",
    "ANXIETY": "anxiety",
    "HEALTH_ISSUES": "health issues",
    "FAMILY_CARE": "family care",
    "TIDYING_UP": "tidying up"
  },
  "GOALS_INTRO": {
    "TITLE": "Goals",
    "INTRO_TITLE": "Set your goals",
    "INTRO_TEXT": "Here you can set, add and track your goals.",
    "START_BUTTON": "Let's go!",
    "SEE_YOUR_INSIGHTS": "Your Insights"
  },
  "GOALS": {
    "TITLE": "Goals",
    "TITLE_SETTINGS": "Goals settings",
    "TEXT": "Which goals would you like to achieve?",
    "SELECT_YOUR_GOALS": "Select your Goals",
    "ADD_GOAL": "Goal",
    "TRACK_YOUR_GOALS": "Track your Goals",
    "GOAL_TITLE_NUMBER": "Goal",
    "EDIT": "Edit",
    "ACTIVE_GOALS": "Active Goals",
    "OVERVIEW_GOALS": "Overview",
    "OVERVIEW_TEXT": "Would you like to change your goals? You can edit them here.",
    "SHARE_TITLE": "Share your goals",
    "SHARE_TEXT": "Do you want consultation? Share your results with our counselors and develop new energizing strategies.",
    "SHARE_BUTTON": "Share results with Advisor",
    "SHARE_PRIVACY_INFO": "Privacy Policy",
    "INSIGHTS_DAYS_WITH_COMPLETED_GOALS": "Your Progress",
    "LAST_21_DAYS": "Last 21 days"
  },
  "EMOJI": {
    "CHOOSE_EMOJI": "Choose Emoji",
    "CHOOSE_YOUR_EMOJI": "Choose an Emoji"
  },
  "UNWANTED_BEHAVIOR": {
    "INTRO_TEXT": "Do you see discrimination, criminal behavior, bullying or other behavior at work that shouldn't exist?",
    "INTRO_REPORT_TEXT": "Do you want to share this with us? ",
    "START_BUTTON": "Next",
    "TITLE": "Unwanted Behaviour",
    "DESCRIBE": "Please describe the situation you want to share. ",
    "WRITE_HERE": "type...",
    "MAKE_REPORT": "Report",
    "OPTION_REPORT_COMPANY": "Please inform my employer as soon as possible.",
    "OPTION_REPORT_ANONYMOUS": "My report is not urgent. Please include it anonymously in the annual report.",
    "BEFORE_SEND_TEXT": "By clicking send, I agree to the storage of my data for the purposes mentioned above.",
    "INFO_POPUP_TITLE": "Information",
    "INFO_POPUP_TEXT": "We forward reported behaviour to our customers twice a year. In urgent cases, immediate forwarding is possible with your consent. ",
    "SEND_POPUP_TITLE": "Are you sure you want to send your message?",
    "SEND_POPUP_TEXT": "Our team will be happy to help you. All information is treated confidentially.",
    "SEND_POPUP_BACK": "No, go back",
    "SEND_POPUP_SEND": "Yes, send report"
  },
  "CONFIRM_SCREEN_SHARE": {
    "ARE_YOU_SURE": "Share your overview with our counsellors",
    "TEXT": "Are you sure you want to share your message?",
    "CANCEL": "No, go back",
    "CONFIRM": "Yes, send results"
  },
  "RANGE": {
    "LAST_WEEK": "Last week",
    "THIS_WEEK": "This week",
    "DAY_LETTER": "D",
    "WEEK_LETTER": "W",
    "MONTH_LETTER": "M",
    "YEAR_LETTER": "Y"
  },
  "TESTS_INTRO": {
    "TITLE": "Tests",
    "INTRO_TEXT": "Here you can find various self tests. These tests are intended as an initial screening to gain insight for your own use, not to be considered as a diagnosis. Your data will be processed anonymous and cannot be traced back to you personally.",
    "INFO_POPUP_TEXT": "Your individual test data are for your own use only. Only if you choose to do so, you can share the data with a counsellor. Counsellors are bound by strict confidentiality rules. We do not add the completed data to a personal profile, nor to the records of the organisation where you work. Only to improve the questionnaires do we save test results in one large database. This data cannot be traced back to you as a user in any way. In this file, we only add age category and gender.",
    "START_BUTTON": "Let's go"
  },
  "TESTS": {
    "TITLE": "Tests",
    "TEXT": "Select the self-tests you want to take here. You can download your results to your device after performing the tests. You can run the tests again at any time. ",
    "TITLE_NOT_COMPLETED": "Pending",
    "TITLE_COMPLETED": "Completed ",
    "QUESTIONS": "Questions",
    "RESULTS": "Test Results",
    "RESULT_SCORE": "Your score is",
    "ALL_TESTS_DONE": "All tests are completed",
    "NO_TESTS_DONE": "No tests completed yet",
    "SHARE_TITLE": "Share your test results",
    "SHARE_TEXT": "Do you want consultation? Share your results with our counselors and develop new energizing strategies.",
    "SHARE_BUTTON": "Share ",
    "SHARE_PRIVACY_INFO": "Privacy Policy"
  }
}
