{
  "BUTTON_NEXT": "Next",
  "BUTTON_SEND": "Send",
  "BUTTON_NEW_WORD": "Add word",
  "PRIVACY_POLICY": "the privacy policy",
  "TERMS": "the terms of use",
  "REGISTER": {
    "TITLE": "For confidential matters.",
    "TEXT": "If your organization has a subscription, you can get in touch with the confidant here.",
    "QR_CODE": "Scan the QR code",
    "TYPE_CODE": "Enter code?",
    "USE_CAMERA": "Scan QR code",
    "TYPE_CODE_PLACEHOLDER": "Code"
  },
  "INTRO_1": {
    "TEXT": "Have you experienced or witnessed an unpleasant event, such as discrimination, bullying, sexual harassment, criminal or other unwanted behavior? And do you want to share this with the confidential advisor of {{COMPANY_NAME}}?"
  },
  "INTRO_2": {
    "TEXT": "{{APP_NAME}} gives you information and connects you with confidant so that you can find your way with this problem in your own pace and in your own way."
  },
  "INTRO_3": {
    "TEXT": "{{APP_NAME}} works safely. Only you and your organisation's confidants  can read the messages. Confidential officers never share personal information without your permission.",
    "I_AGREE_TERMS": "I agree with {{terms}} and {{privacy}}"
  },
  "HOME": {
    "TIPS": "Information",
    "NEW_REPORT": "I want to share something",
    "CHAT": "To the chat ",
    "CODE_INVALID_TEXT": "The code is not valid",
    "HOW_WE_WORK": "How we work",
    "ABOUT_VP_NEDERLAND": "About VP Nederland"
  },
  "NEW_REPORT": {
    "TITLE": "I experienced or witnessed something, what can I do?",
    "INFO_POPUP_TEXT": "Your messages are processed strictly confidentially, and will be automatically deleted from our server after 30 days. We do not store personal profiles. The confidants may ask questions about your messages. You can choose to contact anonymously.",
    "REQUIRED_FIELD_USERNAME": "Required",
    "WORD_SELECT": "",
    "REPORT": "What happened?",
    "FEELINGS": "How do you feel?",
    "SITUATION_DESCRIPTION": "Describe the situation",
    "ABOUT_YOU": "About you",
    "ABOUT_YOU_INFO_POPUP_TEXT": "A user name is obligatory, but you can choose any name or word you prefer.\u00a0The other information is optional; they help to give more detail to the confidant and will be used, anonymously, for the statistics",
    "CONFIDANT_CONFIRM_TITLE": "Share the event with the confidant. The chat is not meant for emergencies. In case of emergency, contact your doctor or dial 112.",
    "CONFIDANT_CONFIRM": "I confirm that I agree with {{terms}} and {{privacy}}",
    "SEND": "Send message",
    "BY_SENDING_CONFIRM": "",
    "TYPE_ANONYMOUS_URGENT_TITLE": "Chat",
    "TYPE_ANONYMOUS_URGENT": "I am struggling with a situation and would like to discuss the options with the confidant",
    "TYPE_ANONYMOUS_NOT_URGENT_TITLE": "Report",
    "TYPE_ANONYMOUS_NOT_URGENT": "I want the confidant to know what is going on",
    "TYPE_CONFIDANT_CALL_TITLE": "Call ",
    "TYPE_CONFIDANT_CALL": "I would like to talk to the confidant on the phone",
    "TYPE_PRIVACY": "I want to know how {{APP_NAME}} handles my data",
    "CONFIDANT_SELECT_TITLE": "Give your preference for a confidant",
    "CONFIDANT_SELECT_TEXT": "Below you can see the confidants who receive your message. Would you like to receive a reply from a specific person? Then you can select that person below. It is possible that due to busy times or vacations you will receive an answer from another person.",
    "CONFIDANT_SELECT_NO_PREFERENCE": "No preference"
  },
  "CHAT": {
    "CHAT_CONSENT": "In case of emergency call 112 or contact the support team by phone.",
    "I_UNDERSTAND": "I understand",
    "DECLINE": "Decline",
    "CONSULTANT_TEAM": "Our consultants",
    "CHAT_INTRO": "Send us a message so that we can support you with your request.",
    "USER_HAS_UNSIGNED_CHATS": "Our chat is currently not live. Then leave a message, our consultants will contact you within the next working day. In case of emergency, contact us by phone or call 112.",
    "NOT_ASSIGNED": "Confidant",
    "NEW_CHAT": "New conversation",
    "INPUT_PLACEHOLDER": "Type...",
    "INPUT_PLACEHOLDER_CONNECTING": "Establishing a secure connection...",
    "CURRENT_CHAT": "Current conversation",
    "YOU_ARE_SPEAKING_WITH": "You are talking to"
  },
  "WORDS": {
    "REPORT": {
      "SCOLDING": "Scolding",
      "DISCRIMINATION": "Discrimination",
      "THREAT": "Threat",
      "ASSAULT": "Assault",
      "SEXUAL_VIOLENCE": "Sexual Violence",
      "SEXUAL_HARASSMENT": "Sexual Harassment",
      "STALKING": "Stalking",
      "BULLYING": "Bullying",
      "FRAUD": "Fraud",
      "CORRUPTION": "Corruption",
      "BLACKMAILING": "Blackmailing",
      "RACISM": "Racism",
      "AGGRESSION": "Aggression",
      "HUMILIATION": "Humiliation",
      "THEFT": "Theft",
      "INTIMIDATION": "Intimidation",
      "PLAGIARISM": "Plagiarism"
    },
    "FEELINGS": {
      "SCARED": "Scared",
      "ANGRY": "Angry",
      "STRESSED": "Stressed",
      "EMBARRASSED": "Embarrassed",
      "LONELY": "Lonely",
      "INSECURE": "Insecure",
      "HUMILIATED": "Humiliated",
      "HURT": "Hurt",
      "OVERWHELMED": "Overwhelmed",
      "CONFUSED": "Confused",
      "THREATENED": "Threatened",
      "UNSAFE": "Unsafe",
      "FRUSTRATED": "Frustrated",
      "MISUNDERSTOOD": "Misunderstood",
      "DESPERATE": "Desperate",
      "DEPRESSED": "Depressed",
      "SHOCKED": "Shocked"
    }
  },
  "CONFIDANTS_INFO": {
    "MENU_ITEM": "Our confidants",
    "TITLE": "Confidants",
    "MAKE_APPOINTMENT": "Make appointment with {{NAME}}",
    "MAKE_APPOINTMENT_TITLE": "Make appointment"
  },
  "MAKE_APPOINTMENT": {
    "SELECTED_CONFIDANT": "Selected confidant: {{NAME}}",
    "LEAVE_YOUR_DETAILS": "Leave your details below at our trust center and make an appointment via the chat.",
    "FIRST_NAME": "First name",
    "LAST_NAME": "Last name",
    "EMAIL": "Email address (private)",
    "PHONE": "Phone number (private)",
    "AGREEMENT": "I confirm that I agree with the terms of use and the privacy policy"
  }
}
