{
  "LANGUAGE_NAME": "Deutsch",
  "MENU": {
    "HOME": "Home",
    "TELEPHONE": "Telefon",
    "CHAT": "Chat",
    "SETTINGS": "Einstellungen"
  },
  "PAGES": {
    "INFO_PAGE": "Infoportal",
    "GOOD_HABITS": "Gute Gewohnheiten"
  },
  "ASK_SUPPORT": {
    "CONTACT_TEXT": "Rufen Sie gerne in unserem Beratungszentrum an",
    "CHAT_TEXT": "Chatten Sie zwischen 8 - 18 Uhr mit unserem Beratungsteam"
  },
  "NOTIFICATIONS": {
    "TITLE": "Benachrichtigung",
    "SELECT_FREQUENCY": "Frequenz",
    "FREQ_NEVER": "Nie",
    "FREQ_DAILY": "T\u00e4glich",
    "FREQ_CHOICE": "Tage selektieren",
    "DAYS": "Tage",
    "TIME": "Uhrzeit"
  },
  "GOOD_HABITS": {
    "INTRO": "Welche guten Gewohnheiten m\u00f6chten Sie in Ihren Alltag \u00fcbernehmen?",
    "ADD_YOUR_HABIT_TEXT": "Markieren Sie Ihre Favoriten mit einem Stern. ",
    "NEW": "Neu",
    "YOU_ARE_ABOUT_TO_REMOVE_A_HABBIT_FROM_FAVORITES": "M\u00f6chten Sie diese gute Gewohnheit aus Ihren Favoriten entfernen? ",
    "YOU_DONT_HAVE_ANY_FAVORITES_YET": "Sie haben noch keine Favoriten ausgew\u00e4hlt",
    "GOOD_HABIT_1_TITLE": "Raus an die frische Luft",
    "GOOD_HABIT_1_TEXT": "15 Minuten drau\u00dfen spazieren gehen",
    "GOOD_HABIT_2_TITLE": "Den Rhythmus finden",
    "GOOD_HABIT_2_TEXT": "Tanzen - zu zweit oder allein",
    "GOOD_HABIT_3_TITLE": "Lyrische Freuden",
    "GOOD_HABIT_3_TEXT": "Jeden Tag in einem Buch lesen",
    "GOOD_HABIT_4_TITLE": "Abschalten",
    "GOOD_HABIT_4_TEXT": "Das Smartphone au\u00dferhalb des Schlafzimmers \u00fcbernachten lassen",
    "GOOD_HABIT_5_TITLE": "Freude verbreiten",
    "GOOD_HABIT_5_TEXT": "Anderen Menschen ein L\u00e4cheln schenken",
    "GOOD_HABIT_6_TITLE": "Musik",
    "GOOD_HABIT_6_TEXT": "Meine Lieblingsk\u00fcnstler h\u00f6ren",
    "GOOD_HABIT_7_TITLE": "Tag Revue passieren lassen",
    "GOOD_HABIT_7_TEXT": "Abends an die guten Momente des Tages denken und dankbar sein",
    "GOOD_HABIT_8_TITLE": "Blumenfreude",
    "GOOD_HABIT_8_TEXT": "Am Freitag Blumen f\u00fcrs Wochenende kaufen",
    "GOOD_HABIT_9_TITLE": "Weniger hetzen",
    "GOOD_HABIT_9_TEXT": "Zeitliche Puffer einbauen und die geschenkte Zeit genie\u00dfen",
    "GOOD_HABIT_10_TITLE": "Raus an die frische Luft",
    "GOOD_HABIT_10_TEXT": "Zeit in der Natur verbringen",
    "GOOD_HABIT_11_TITLE": "Treppen",
    "GOOD_HABIT_11_TEXT": "Mehr Treppen, weniger Aufz\u00fcge",
    "GOOD_HABIT_12_TITLE": "Boden f\u00fchlen",
    "GOOD_HABIT_12_TEXT": "Barfu\u00df gehen",
    "GOOD_HABIT_13_TITLE": "Freude verbreiten",
    "GOOD_HABIT_13_TEXT": "Sagen, was mir gef\u00e4llt!",
    "GOOD_HABIT_14_TITLE": "Liebe geht durch den Magen",
    "GOOD_HABIT_14_TEXT": "Mit anderen zusammen kochen",
    "GOOD_HABIT_15_TITLE": "Spielfreuden",
    "GOOD_HABIT_15_TEXT": "Mal wieder etwas spielen",
    "GOOD_HABIT_16_TITLE": "Geschm\u00e4cker austesten",
    "GOOD_HABIT_16_TEXT": "Neue Teesorten probieren",
    "GOOD_HABIT_17_TITLE": "Frisch bezogen",
    "GOOD_HABIT_17_TEXT": "Bettw\u00e4sche waschen, drau\u00dfen aufh\u00e4ngen, abends darin schlafen - herrlich!",
    "GOOD_HABIT_18_TITLE": "Gutes Tun",
    "GOOD_HABIT_18_TEXT": "Jeden Tag eine gute Tat",
    "GOOD_HABIT_19_TITLE": "Den Fu\u00dfspitzen so nah",
    "GOOD_HABIT_19_TEXT": "Morgens strecken, dehnen oder Yoga",
    "GOOD_HABIT_20_TITLE": "Bewegung",
    "GOOD_HABIT_20_TEXT": "Jeden Tag eine Runde um den Block spazieren",
    "GOOD_HABIT_21_TITLE": "Geschm\u00e4cker austesten",
    "GOOD_HABIT_21_TEXT": "Achtsam essen: Wie entfaltet sich der Geschmack?",
    "GOOD_HABIT_22_TITLE": "Digitales Fasten",
    "GOOD_HABIT_22_TEXT": "Auszeiten schaffen von Elektronik",
    "GOOD_HABIT_23_TITLE": "Sch\u00f6n f\u00fchlen",
    "GOOD_HABIT_23_TEXT": "Sch\u00f6ne Kleidung anziehen",
    "GOOD_HABIT_24_TITLE": "Ausmisten",
    "GOOD_HABIT_24_TEXT": "Unn\u00f6tiges im Alltag abschaffen",
    "GOOD_HABIT_25_TITLE": "Kunst genie\u00dfen",
    "GOOD_HABIT_25_TEXT": "Online ein Konzert oder Museum besuchen"
  },
  "LOGIN": {
    "NEXT": "WEITER",
    "INTRO_TEXT": "Code",
    "NAME_FIELD": "Benutzername",
    "REQUIRED_FIELD": "Erforderlich",
    "NAME_OR_PSEUDONYM_REQUIRED": "Bitte tragen Sie einen Benutzernamen ein.",
    "CODE_NOT_CORRECT": "Der angegebene Code ist nicht korrekt.",
    "AGE_GROUP": "Alter",
    "YEARS_OLD": "Jahre",
    "I_AGREE_TERMS": "Ich stimme den {{terms}} zu",
    "TERMS_TEXT": "Nutzungsbedingungen",
    "LOGIN_CONSENT_LINK_TEXT": "Datenschutzerkl\u00e4rung",
    "LOGIN_CONSENT": "Mit der Anmeldung stimmen Sie der Datenverarbeitung zu. Mehr Informationen gibt es in der {{data}}.",
    "LOGIN": "LOGIN"
  },
  "HOME": {
    "CODE_INVALID_TEXT": "Sie wurden abgemeldet, weil Ihr Code ung\u00fcltig ist.",
    "BACKGROUND_ARTICLES": "Artikel",
    "BACKGROUND_ARTICLES_SUB": "Lassen Sie sich inspirieren!",
    "GOOD_HABITS": "Gute Gewohnheiten",
    "REFLECTION": "Reflexion",
    "GOALS": "Ziele",
    "WHAT_IS_EAP": "Wie wir beraten",
    "QUICK_CHECKLISTS": "Checklisten",
    "QUICK_CHAT": "Chat",
    "QUICK_TELEPHONE": "Telefon",
    "QUICK_RELAX": "Entspannen",
    "QUICK_PODCASTS": "Podcasts",
    "QUICK_VIDEOS": "Videos",
    "QUICK_APPOINTMENTS": "Terminvergabe",
    "TOPICAL": "Aktuelles",
    "ARTICLE_OVERVIEW": "Artikel\u00fcbersicht",
    "ARTICLE_OVERVIEW_SUBTITLE": "Alle Kategorien",
    "CONTACT": "Telefon",
    "UNWANTED_BEHAVIOR": "Unerw\u00fcnschtes Verhalten",
    "SHOW_ALL_CATEGORIES": "Zu den Artikeln",
    "ARTICLE_OF_THE_WEEK": "Artikel der Woche",
    "TESTS": "Tests"
  },
  "INFO_PAGES": {
    "THEMES_INFO": "Lassen Sie sich von unseren Artikeln informieren und inspirieren.",
    "OTHER_THEMES": "Andere Themen",
    "NEED_SUPPORT": "Wie k\u00f6nnen wir Ihnen weiterhelfen?",
    "ARTICLES": "Artikel"
  },
  "INFO_PAGE": {
    "TITLE": "Infoportal",
    "READING_TIME": "{{minutes}} Min. Lesezeit",
    "READING_TIME_ALT": "Lesezeit: {{minutes}} Min."
  },
  "CONTACT": {
    "TITLE": "Telefon",
    "CONTENT": "Rufen Sie uns gerne mit Ihrem Anliegen an. Im Notfall sind wir rund um die Uhr erreichbar.",
    "CALL": "Telefonieren",
    "CALL_DESCRIPTION": "Kontaktieren Sie unser Beratungsteam",
    "TELEPHONE": "Telefon",
    "TELEPHONE_SAVE_OR_SHARE": "Telefonnummer *",
    "TELEPHONE_NUMBER_1": "0800 3212345",
    "TELEPHONE_NUMBER_1_ADDITION": "(Deutschland)",
    "TELEPHONE_NUMBER_2": "00800 32123456",
    "TELEPHONE_NUMBER_2_ADDITION": "(\u00d6sterreich)",
    "TELEPHONE_NUMBER_3_TITLE": "Aus dem Ausland **",
    "TELEPHONE_NUMBER_3": "+49 431 895 19 32",
    "TELEPHONE_NUMBER_3_ADDITION": "",
    "ADDITIONAL_INFO_1": "* kostenfrei",
    "ADDITIONAL_INFO_2": "** es fallen Geb\u00fchren an",
    "CHAT": "Chat"
  },
  "SETTINGS": {
    "TITLE": "Einstellungen",
    "LANGUAGE": "Sprache",
    "NOTIFICATIONS": "Benachrichtigungen - Reflexion",
    "FEEDBACK": "Feedback zur App",
    "PRIVACY_POLICY": "Datenschutz",
    "USER_CONDITIONS": "Nutzungsbedingungen",
    "ABOUT_OS_LICENSES": "\u00dcber / Open-Source-Lizenzen"
  },
  "CHAT": {
    "CHAT_CONSENT": "Wenn Sie sich in einer Notsituation befinden, wenden Sie sich bitte telefonisch an uns oder w\u00e4hlen Sie 112. ",
    "I_UNDERSTAND": "Verstanden",
    "DECLINE": "Ablehnen",
    "CONSULTANT_TEAM": "Unser Beratungsteam",
    "CHAT_INTRO": "Schicken Sie uns eine Nachricht, damit wir Ihnen bei Ihrem Anliegen helfen k\u00f6nnen.",
    "USER_HAS_UNSIGNED_CHATS": "Unser Chat ist zurzeit nicht besetzt. Hinterlassen Sie eine Nachricht, unsere Berater melden sich zeitnah bei Ihnen. W\u00e4hlen Sie im Notfall unsere Telefonnummer.",
    "NOT_ASSIGNED": "Berater",
    "NEW_CHAT": "Neues Gespr\u00e4ch",
    "INPUT_PLACEHOLDER": "schreiben...",
    "INPUT_PLACEHOLDER_CONNECTING": "Sichere Verbindung herstellen...",
    "CURRENT_CHAT": "Zur\u00fcck zum Gespr\u00e4ch",
    "YOU_ARE_SPEAKING_WITH": "Sie sprechen mit..."
  },
  "AUDIO_PLAYER": {
    "DOWNLOADING": "Herunterladen.."
  },
  "FEEDBACK": {
    "TITLE": "Feedback zur App",
    "INPUT_PLACEHOLDER": "Schreiben...",
    "INTRO": "Wir entwickeln unsere App stets f\u00fcr Sie weiter. Wenn sie Feedback zur App haben, k\u00f6nnen Sie es uns gerne hier mitteilen. Dieses Formular ist nicht daf\u00fcr bestimmt, pers\u00f6nliche Informationen zu teilen.",
    "NAME": "Name",
    "NAME_REQUIRED": "Bitte geben Sie einen Namen an",
    "EMAIL": "E-Mail-Adresse",
    "EMAIL_INVALID": "Bitte geben Sie eine g\u00fcltige E-Mail-Adresse an",
    "EMAIL_REQUIRED": "Bitte geben Sie eine E-Mail-Adresse an",
    "MESSAGE": "Nachricht ",
    "MESSAGE_REQUIRED": "Bitte hinterlassen Sie eine Nachricht",
    "SUBMIT_SUCCESS": "Ihr Feedback wurde versendet",
    "SEND": "Senden"
  },
  "REFLECTION_INTRO": {
    "TITLE": "Wie geht es Ihnen?",
    "INTRO_TITLE": "Reflexion",
    "INTRO_TEXT": "Hier k\u00f6nnen Sie Tagebuch dar\u00fcber f\u00fchren, wie Sie sich f\u00fchlen und welche Faktoren in Ihrem Alltag Ihr Energieniveau beeinflussen."
  },
  "REFLECTION": {
    "TITLE": "Wie geht es Ihnen?",
    "TITLE_WORK": "Bei der Arbeit",
    "TITLE_SPARE_TIME": "In der Freizeit",
    "TITLE_INSIGHTS": "\u00dcbersicht",
    "TITLE_REMINDERS": "Erinnerungen",
    "TITLE_SETTINGS": "Einstellungen: Reflexion",
    "HOW_DO_YOU_FEEL_TODAY": "",
    "SELECT_YOUR_MOOD": "Wie f\u00fchlen Sie sich heute?",
    "DESCRIBE_YOUR_FEELINGS": " ",
    "FEELINGS_WORK": "",
    "FEELINGS_SPARE_TIME": "",
    "ADVICE": "Tipps",
    "FEELINGS_INSIGHTS": "Stimmung",
    "WORK_INSIGHTS": "Arbeit",
    "SPARE_TIME_INSIGHTS": "Freizeit",
    "SHARE_TITLE": "Teilen Sie Ihre \u00dcbersicht",
    "SHARE_TEXT": "W\u00fcnschen Sie Beratung? Teilen Sie Ihre Ergebnisse mit unserem Beratungsteam und entwickeln Sie neue energetisierende Strategien.",
    "SHARE_BUTTON": "Teilen",
    "SHARE_PRIVACY_INFO": "Datenschutz",
    "SETTINGS_INTRO": "Schaffen Sie mehr Leichtigkeit, indem Sie ihre Gem\u00fctszust\u00e4nde achtsam beobachten.",
    "SETTINGS_INFO_TITLE": "Lernen Sie mehr \u00fcber sich!",
    "SETTINGS_INFO_TEXT": "Entdecken Sie, wie Sie sich \u00fcber einen l\u00e4ngeren Zeitraum hinweg f\u00fchlen.",
    "SETTINGS_REMINDER_TITLE": "M\u00f6chten Sie an die Reflexion erinnert werden?",
    "SETTINGS_REMINDER_FREQUENCY": "Frequenz",
    "SETTINGS_REMINDER_DAYS": "Tage",
    "SETTINGS_REMINDER_TIME_OF_DAY": "Tageszeit",
    "SETTINGS_REMINDER_SELECT_TIME": "Uhrzeit",
    "SETTINGS_REMINDER_FREQUENCY_OFF": "Nie",
    "SETTINGS_REMINDER_FREQUENCY_DAILY": "T\u00e4glich",
    "SETTINGS_REMINDER_FREQUENCY_WEEKLY": "W\u00f6chentlich",
    "SETTINGS_REMINDER_FREQUENCY_TWICE_A_WEEK": "Zweimal pro Woche",
    "SETTINGS_REMINDER_FREQUENCY_MONTHLY": "Monatlich",
    "SETTINGS_REMINDER_FREQUENCY_TWICE_A_MONTH": "Zweimal im Monat",
    "SETTINGS_SAVE": "Speichern",
    "FEELINGS_TOP": "Top {{NUM}} Stimmung ",
    "WORK_TOP": "Top {{NUM}} Energie bei der Arbeit",
    "SPARE_TIME_TOP": "Top {{NUM}} Energie in der Freizeit",
    "POSITIVE": "Positiv",
    "NEGATIVE": "Negativ",
    "ENERGY_TAKERS": "Energiefresser",
    "ENERGY_GIVERS": "Energiespender",
    "ADD_WORD": "Wort hinzuf\u00fcgen"
  },
  "INSIGHTS": {
    "PAGE_TITLE": "\u00dcbersicht",
    "TITLE": "Behalten Sie hier die \u00dcbersicht \u00fcber Ihre Erkenntnisse bei der Selbstentwicklung."
  },
  "INSIGHTS_SHARE": {
    "TITLE": "Teilen Sie Ihre \u00dcbersicht mit dem Beratungsteam, wenn Sie Ihre Ergebnisse besprechen oder Ver\u00e4nderung in Ihren Alltag bringen wollen. ",
    "TEXT": "",
    "MESSAGE_PLACEHOLDER": "Wie kann unser Team Ihnen weiterhelfen?",
    "SEND_TO_COUNSELOR": "Senden",
    "REFLECTION": "Reflexion",
    "LAST_WEEK": "Letzte Woche",
    "LAST_MONTH": "Letzter Monat"
  },
  "MOODS": {
    "VERY_BAD": "sehr schlecht",
    "BAD": "schlecht",
    "MODERATE": "m\u00e4\u00dfig",
    "GOOD": "gut",
    "VERY_GOOD": "sehr gut"
  },
  "DAYS": {
    "MONDAY": "Montag",
    "TUESDAY": "Dienstag",
    "WEDNESDAY": "Mittwoch",
    "THURSDAY": "Donnerstag",
    "FRIDAY": "Freitag",
    "SATURDAY": "Samstag",
    "SUNDAY": "Sonntag"
  },
  "LOADING": "Laden..",
  "BACK": "Zur\u00fcck",
  "POSSIBLY_NO_INTERNET_AVAILABLE": "Es konnte keine Verbindung mit dem Server hergestellt werden. \u00dcberpr\u00fcfen Sie Ihre Internetverbindung oder versuchen Sie es sp\u00e4ter erneut.",
  "NAME_OR_PSEUDONYM": "Benutzername",
  "GENDER": "Gender",
  "GENDER_MALE_CHAR": "m",
  "GENDER_FEMALE_CHAR": "w",
  "GENDER_DIVERSE_CHAR": "d",
  "GENDER_MALE": "M\u00e4nnlich",
  "GENDER_FEMALE": "Weiblich",
  "GENDER_DIVERSE": "Divers",
  "BUTTON_OK": "L\u00f6schen",
  "BUTTON_SAVE": "Speichern",
  "BUTTON_DONE": "Fertig",
  "BUTTON_CANCEL": "Abbrechen",
  "BUTTON_CLOSE": "Schlie\u00dfen",
  "BUTTON_PREVIOUS": "Zur\u00fcck",
  "BUTTON_NEXT": "Weiter",
  "BUTTON_ADD": "hinzuf\u00fcgen",
  "BUTTON_NEW_WORD": "neues Wort",
  "ARE_YOU_SURE": "Bist du sicher?",
  "LOGOUT": "L\u00f6schen",
  "LOGOUT_TEXT": "Wenn Sie sich abmelden, werden all Ihre lokal gespeicherten Daten gel\u00f6scht",
  "SETTINGS_SAVED": "Ihre Einstellungen wurden gespeichert",
  "OPEN": "\u00d6ffnen",
  "FEELINGS_POS": {
    "OPTIMISTIC": "optimistisch",
    "TRUSTING": "vertrauensvoll",
    "PEACEFUL": "friedlich",
    "POWERFUL": "leistungsf\u00e4hig",
    "ACCEPTED": "akzeptiert",
    "PROUD": "stolz",
    "CONTENT": "zufrieden",
    "PLAYFUL": "verspielt",
    "ENERGETIC": "energiegeladen",
    "CONFIDENT": "selbstbewusst",
    "JOYFUL": "fr\u00f6hlich",
    "PASSIONATE": "leidenschaftlich",
    "LOVED": "geliebt",
    "EAGER": "eifrig",
    "RELAXED": "entspannt",
    "MOTIVATED": "motiviert",
    "HAPPY": "gl\u00fccklich",
    "GRATEFUL": "dankbar",
    "INSPIRED": "inspiriert",
    "EXCITED": "aufgeregt",
    "NOTICED": "beachtet",
    "MINDFUL": "achtsam",
    "CALM": "ruhig",
    "RELIEVED": "erleichtert"
  },
  "FEELINGS_NEG": {
    "STRESSED": "gestresst",
    "TIRED": "m\u00fcde",
    "CONFUSED": "verwirrt",
    "INSECURE": "Unsicher",
    "FRUSTRATED": "frustriert",
    "CRITICAL": "kritisch",
    "DISAPPOINTED": "entt\u00e4uscht",
    "HURT": "verletzt",
    "GUILTY": "schuldig",
    "DEPRESSED": "depressiv",
    "LONELY": "einsam",
    "WORRIED": "besorgt",
    "EMBARRASSED": "verlegen",
    "AFRAID": "\u00e4ngstlich",
    "SAD": "traurig",
    "ANXIOUS": "beunruhigt",
    "UNHAPPY": "ungl\u00fccklich",
    "BORED": "gelangweilt",
    "EXHAUSTED": "ersch\u00f6pft",
    "PANICKED": "panisch",
    "ANGRY": "w\u00fctend",
    "ANNOYED": "genervt",
    "ASHAMED": "besch\u00e4mt",
    "JEALOUS": "eifers\u00fcchtig"
  },
  "WORK_POS": {
    "TEAMWORK": "Teamarbeit",
    "GETTING_COMPLIMENTS": "Komplimente bekommen",
    "COFFEE_WITH_COLLEAGUES": "Kaffee mit Kollegen",
    "SUPPORT_BY_MANAGER": "Unterst\u00fctzung vom Manager",
    "SUPPORT_BY_COLLEAGUES": "Unterst\u00fctzung von Kollegen",
    "ACKNOWLEDGMENT": "Anerkennung",
    "HAVING_RESPONSIBILITY": "Verantwortung haben",
    "JOB_SECURITY": "Jobsicherheit",
    "LIVELY_DISCUSSIONS": "lebhafte Diskussionen",
    "A_FULL_SCHEDULE": "ein voller Kalender",
    "CONTACT_WITH_CLIENTS": "Kontakt zu Klienten",
    "ENERGETIC_MEETINGS": "angeregte Besprechungen",
    "SOLVING_PROBLEMS": "Probleme l\u00f6sen",
    "FINDING_CREATIVE_SOLUTIONS": "kreative L\u00f6sungen finden",
    "GIVING_PRESENTATIONS": "Pr\u00e4sentationen halten",
    "LISTENING_TO_PRESENTATIONS": "Pr\u00e4sentationen anh\u00f6ren",
    "DELIVERING_QUALITY": "Qualit\u00e4t liefern",
    "PREVENTING_ERRORS": "Fehler vorbeugen",
    "STRUCTURED_WORK": "strukturiert arbeiten",
    "WORKING_ACCORDING_TO_PLAN": "nach Plan arbeiten",
    "HAVING_OVERVIEW": "\u00dcberblick haben",
    "CELEBRATING_SUCCESS": "Erfolge feiern",
    "A_SHORT_BREAK_OUTSIDE": "kurze Pausen drau\u00dfen"
  },
  "WORK_NEG": {
    "TOUGH_MEETINGS": "schwierige Besprechungen",
    "BORING_PRESENTATIONS": "langweilige Pr\u00e4sentationen",
    "TOUGH_COMMUNICATION": "Kommunikation",
    "GOAL_UNCLARITY": "unklare Zielsetzung",
    "TIME_PRESSURE": "Zeitdruck",
    "OVERTIME": "\u00dcberstunden",
    "FEAR_OF_FAILURE": "Versagensangst",
    "DISRUPTION": "Unterbrechungen",
    "LACK_OF_CONTROL": "mangelnde Kontrolle",
    "MUCH_RESPONSIBILITY": "viel Verantwortung",
    "JOB_INSECURITY": "Arbeitsunsicherheit",
    "SHIFT_WORK": "Schichtarbeit",
    "REMOTEWORK": "Telearbeit",
    "NOISE": "L\u00e4rm",
    "DISCUSSIONS": "Diskussionen",
    "MULTITASKING": "Multitasking",
    "CONFLICTS": "Konflikte",
    "ILLNESS": "Krankheit",
    "BEING_UNINFORMED": "uninformiert sein",
    "TRAFFIC_JAM": "Stau",
    "HUSTLE": "Trubel",
    "HOMESICKNESS": "Heimweh",
    "BOREDOM": "Langeweile",
    "LACK_OF_APPRECIATION": "fehlende Anerkennung"
  },
  "SPARE_TIME_POS": {
    "MEDITATION": "Meditation",
    "PLAYING_GAMES": "Spiele spielen",
    "BEING_ALONE": "alleine sein",
    "READING": "lesen",
    "COOKING": "kochen",
    "PLAYING_MUSIC": "Musik machen",
    "SPORTS": "Sport",
    "SHOPPING": "shoppen",
    "ACKNOWLEDGMENT": "Anerkennung",
    "DISCUSSIONS": "Diskussionen",
    "LOAFING": "faulenzen",
    "PRIVACY": "Privatsph\u00e4re",
    "MEETING_FRIENDS": "Freunde treffen",
    "MEETING_FAMILY": "Familie treffen",
    "GOING_OUTSIDE": "hinausgehen",
    "PLAYING_WITH_THE_KIDS": "mit den Kindern spielen",
    "WATCHING_TV": "fernsehen",
    "READING_A_BOOK": "ein Buch lesen",
    "GARDENING": "g\u00e4rtnern",
    "A_SHORT_WALK": "ein kurzer Spaziergang",
    "FEELING_THE_WIND": "den Wind sp\u00fcren",
    "FEELING_THE_SUN": "die Sonne sp\u00fcren",
    "GOING_TO_A_CONCERT": "zu einem Konzert gehen",
    "FESTIVALS": "Festivals",
    "THEATER": "Theater"
  },
  "SPARE_TIME_NEG": {
    "FRIENDS": "Freunde",
    "FAMILY": "Familie",
    "BEING_ALONE": "Alleinsein",
    "SLEEP_DEPRIVATION": "Schlafmangel",
    "COOKING": "kochen",
    "MOVEMENT": "Bewegung",
    "GROCERY_SHOPPING": "Einkaufen",
    "NOISE": "L\u00e4rm",
    "DISCUSSIONS": "Diskussionen",
    "MULTITASKING": "Multitasking",
    "CONFLICTS": "Konflikte",
    "ILLNESS": "Krankheit",
    "PERFECTIONISM": "Perfektionismus",
    "CONCERNS": "Sorgen",
    "TRAFFIC_JAM": "Stau",
    "BEING_UNINFORMED": "uninformiert sein",
    "SELF_DOUBT": "Selbstzweifel",
    "DEBT": "Schulden",
    "HUSTLE": "Trubel",
    "HOMESICKNESS": "Heimweh",
    "ANXIETY": "\u00c4ngstlichkeit",
    "HEALTH_ISSUES": "gesundheitliche Probleme",
    "FAMILY_CARE": "Familienpflege",
    "TIDYING_UP": "aufr\u00e4umen"
  },
  "GOALS_INTRO": {
    "TITLE": "Ziele",
    "INTRO_TITLE": "Setzen Sie sich Ziele!",
    "INTRO_TEXT": "Hier k\u00f6nnen Sie Ihre pers\u00f6nlichen Ziele definieren und verfolgen. ",
    "START_BUTTON": "Los geht's!",
    "SEE_YOUR_INSIGHTS": "Zur \u00dcbersicht"
  },
  "GOALS": {
    "TITLE": "Ziele",
    "TITLE_SETTINGS": "Einstellungen: Ziele",
    "TEXT": "Welche Ziele m\u00f6chten Sie erreichen? ",
    "SELECT_YOUR_GOALS": "W\u00e4hlen Sie Ihre Ziele aus",
    "ADD_GOAL": "Ziel",
    "TRACK_YOUR_GOALS": "Verfolgen Sie Ihre Ziele",
    "GOAL_TITLE_NUMBER": "Ziel",
    "EDIT": "bearbeiten",
    "ACTIVE_GOALS": "Aktuelle Ziele",
    "OVERVIEW_GOALS": "",
    "OVERVIEW_TEXT": "M\u00f6chten Sie Ziele entfernen oder hinzuf\u00fcgen? Hier k\u00f6nnen Sie Ihre Ziele bearbeiten.",
    "SHARE_TITLE": "Teilen Sie Ihre \u00dcbersicht",
    "SHARE_TEXT": "W\u00fcnschen Sie Beratung? Teilen Sie Ihre Ergebnisse mit unserem Beratungsteam und entwickeln Sie neue energetisierende Strategien.",
    "SHARE_BUTTON": "Teilen",
    "SHARE_PRIVACY_INFO": "Datenschutz",
    "INSIGHTS_DAYS_WITH_COMPLETED_GOALS": "Ihr Fortschritt",
    "LAST_21_DAYS": "Letzte 21 Tage"
  },
  "EMOJI": {
    "CHOOSE_EMOJI": "Emoji w\u00e4hlen",
    "CHOOSE_YOUR_EMOJI": "Emoji w\u00e4hlen"
  },
  "UNWANTED_BEHAVIOR": {
    "INTRO_TEXT": "Nehmen Sie bei ihrer Arbeit Diskrimierung, Kriminalit\u00e4t, Mobbing oder anderes Verhalten wahr, das es eigentlich nicht geben sollte?",
    "INTRO_REPORT_TEXT": "M\u00f6chten Sie dies melden?",
    "START_BUTTON": "Weiter",
    "TITLE": "Unerw\u00fcnschtes Verhalten",
    "DESCRIBE": "Beschreiben Sie hier die Erfahrung, die Sie mitteilen m\u00f6chten. ",
    "WRITE_HERE": "schreiben ...",
    "MAKE_REPORT": "Senden",
    "OPTION_REPORT_COMPANY": "Corrente soll meinen Arbeitgeber so schnell wie m\u00f6glich informieren. ",
    "OPTION_REPORT_ANONYMOUS": "Mein Meldung ist nicht akut. Corrente darf die anonyme Meldung in den Jahresbericht aufnehmen.",
    "BEFORE_SEND_TEXT": "Indem ich auf Senden dr\u00fccke, erkl\u00e4re ich mich einverstanden mit der Speicherung meiner Daten f\u00fcr die oben genannten Zwecke.",
    "INFO_POPUP_TITLE": "Information",
    "INFO_POPUP_TEXT": "Wir leiten gemeldetes Verhalten zwei Mal im Jahr an usere Kunden weiter. In dringenden F\u00e4llen ist mit Ihrer Zustimmung eine sofortige Weiterleitung m\u00f6glich. ",
    "SEND_POPUP_TITLE": "Sind Sie sich sicher, dass Sie Ihre Nachricht versenden m\u00f6chten?",
    "SEND_POPUP_TEXT": "Unser Team hilft Ihnen gerne weiter. Alle Informationen werden vertraulich behandelt.",
    "SEND_POPUP_BACK": "Nein, zur\u00fcck",
    "SEND_POPUP_SEND": "Ja, senden"
  },
  "CONFIRM_SCREEN_SHARE": {
    "ARE_YOU_SURE": "Teilen Sie Ihre \u00dcbersicht mit dem Beratungsteam",
    "TEXT": "Sind Sie sich sicher, dass Sie Ihre Nachricht versenden m\u00f6chten?",
    "CANCEL": "Nein, zur\u00fcck",
    "CONFIRM": "Ja, senden"
  },
  "RANGE": {
    "LAST_WEEK": "letzte Woche",
    "THIS_WEEK": "diese Woche",
    "DAY_LETTER": "T",
    "WEEK_LETTER": "W",
    "MONTH_LETTER": "M",
    "YEAR_LETTER": "J"
  },
  "TESTS_INTRO": {
    "TITLE": "Tests",
    "INTRO_TEXT": "Hier liegen eine Anzahl Selbsttests f\u00fcr Sie bereit. Die Tests sind als erstes Mittel bestimmt, um Erkenntnisse \u00fcber sich selbst zu erhalten. Die Tests sind nicht als Diagnose zu betrachten. Ihre Daten werden anonym verarbeitet.",
    "INFO_POPUP_TEXT": "Wie gehen wir mit Ihren Daten um? Ihre individuellen Testdaten sind nur f\u00fcr Ihren eigenen Gebrauch bestimmt. Nur wenn Sie dies w\u00fcnschen, k\u00f6nnen Sie die Daten an einen Berater weitergeben. Unser Beratungsteam unterliegt der Schweigepflicht. Wir f\u00fcgen die ausgef\u00fcllten Daten weder einem pers\u00f6nlichen Profil noch den Unterlagen der Organisation, in der Sie arbeiten, hinzu. Nur um die Frageb\u00f6gen zu verbessern, speichern wir die Testergebnisse in einer gro\u00dfen Datendatei. Diese Daten k\u00f6nnen in keiner Weise zu Ihnen als Nutzer zur\u00fcckverfolgt werden. In dieser Datei f\u00fcgen wir nur die Alterskategorie und das Geschlecht hinzu.",
    "START_BUTTON": "Los geht's!"
  },
  "TESTS": {
    "TITLE": "Tests",
    "TEXT": "W\u00e4hlen Sie hier die Selbsttests aus, die Sie machen m\u00f6chten. Ihre Ergebnisse k\u00f6nnen Sie nach Durchf\u00fchrung der Tests auf Ihr Ger\u00e4t herunterladen. Sie k\u00f6nnen die Tests jederzeit erneut durchf\u00fchren. ",
    "TITLE_NOT_COMPLETED": "Ausstehend",
    "TITLE_COMPLETED": "Vollendet",
    "QUESTIONS": "Fragen",
    "RESULTS": "Testergebnis",
    "RESULT_SCORE": "Ihr Ergebnis ist",
    "ALL_TESTS_DONE": "Alle Tests vollendet",
    "NO_TESTS_DONE": "Noch keine vollendeten Tests",
    "SHARE_TITLE": "Teilen Sie Ihre \u00dcbersicht",
    "SHARE_TEXT": "W\u00fcnschen Sie Beratung? Teilen Sie Ihre Ergebnisse mit unserem Beratungsteam und entwickeln Sie neue energetisierende Strategien.",
    "SHARE_BUTTON": "Teilen",
    "SHARE_PRIVACY_INFO": "Datenschutz"
  }
}
