{
  "BUTTON_NEXT": "Volgende",
  "BUTTON_SEND": "Verzenden",
  "BUTTON_NEW_WORD": "Woord toevoegen",
  "PRIVACY_POLICY": "het privacybeleid",
  "TERMS": "de gebruiksvoorwaarden",
  "REGISTER": {
    "TITLE": "Voor vertrouwenszaken.",
    "TEXT": "Als je organisatie een abonnement heeft, krijg je hier contact met je vertrouwenspersoon.",
    "QR_CODE": "Scan QR-code",
    "TYPE_CODE": "Code invullen?",
    "USE_CAMERA": "Code scannen?",
    "TYPE_CODE_PLACEHOLDER": "Code"
  },
  "INTRO_1": {
    "TEXT": "Heb je een onaangename gebeurtenis meegemaakt of gezien, zoals discriminatie, pesten, seksueel grensoverschrijdend gedrag, criminaliteit, of ander ongewenst of niet-integer gedraag? En wil je dit met de vertrouwenspersoon van {{COMPANY_NAME}} delen?"
  },
  "INTRO_2": {
    "TEXT": "{{APP_NAME}} geeft je informatie en brengt je in contact met je vertrouwenspersoon zodat je in je eigen tijd en op je eigen manier je weg kunt vinden met dit probleem."
  },
  "INTRO_3": {
    "TEXT": "{{APP_NAME}} werkt veilig. Alleen jij en de vertrouwenspersonen van jouw organisatie kunnen de berichten lezen. Vertrouwenspersonen delen persoonlijke informatie nooit zonder jouw toestemming.",
    "I_AGREE_TERMS": "Ik ga akkoord met {{terms}} en {{privacy}}"
  },
  "HOME": {
    "TIPS": "Vertrouwenszaken bij {{COMPANY_NAME}}",
    "NEW_REPORT": "Ik wil iets kwijt",
    "CHAT": "Naar de chat",
    "CODE_INVALID_TEXT": "De code is ongeldig. Probeer het opnieuw.",
    "HOW_WE_WORK": "Hoe wij werken",
    "ABOUT_VP_NEDERLAND": "Over VP Nederland"
  },
  "NEW_REPORT": {
    "TITLE": "Ik heb iets meegemaakt of gezien, wat nu?",
    "INFO_POPUP_TEXT": "Je berichten worden strikt vertrouwelijk verwerkt, en worden na 30 dagen automatisch van onze server verwijderd. We slaan geen persoonlijke profielen op. De vertrouwenspersonen kunnen mogelijk vragen over je berichten stellen. Je mag ervoor kiezen om anoniem contact op te nemen.",
    "REQUIRED_FIELD_USERNAME": "Verplicht",
    "WORD_SELECT": "",
    "REPORT": "Wat is er gebeurd?",
    "FEELINGS": "Hoe voel je je?",
    "SITUATION_DESCRIPTION": "Beschrijf de situatie ",
    "ABOUT_YOU": "Over jou",
    "ABOUT_YOU_INFO_POPUP_TEXT": "Een bijnaam of gebruikersnaam is verplicht, maar je mag elke naam of elk woord kiezen die je wilt.\u00a0De andere gegevens zijn vrijblijvend; ze geven de vertrouwenspersoon meer informatie en gebruiken we geanonimiseerd voor de statistieken.",
    "CONFIDANT_CONFIRM_TITLE": "Verstuur de melding naar je vertrouwenspersoon. De chat is niet voor acute noodgevallen bedoeld. Daarvoor bel je de huisarts of 112.",
    "CONFIDANT_CONFIRM": "Ik bevestig dat ik akkoord ga met {{terms}} en {{privacy}}",
    "SEND": "Verstuur je melding",
    "BY_SENDING_CONFIRM": "",
    "TYPE_ANONYMOUS_URGENT_TITLE": "Chatten",
    "TYPE_ANONYMOUS_URGENT": "Ik zit met een situatie in mijn maag en wil met de vertrouwenspersoon bespreken wat de opties zijn",
    "TYPE_ANONYMOUS_NOT_URGENT_TITLE": "Melden",
    "TYPE_ANONYMOUS_NOT_URGENT": "Ik wil een signaal afgeven aan de vertrouwenspersoon",
    "TYPE_CONFIDANT_CALL_TITLE": "Bellen",
    "TYPE_CONFIDANT_CALL": "Ik wil graag telefonisch met een vertrouwenspersoon spreken ",
    "TYPE_PRIVACY": "Ik wil weten hoe {{APP_NAME}} met mijn gegevens omgaat",
    "CONFIDANT_SELECT_TITLE": "Geef je voorkeur voor een vertrouwenspersoon op",
    "CONFIDANT_SELECT_TEXT": "Hieronder zie je de vertrouwenspersonen bij {{COMPANY_NAME}} die je bericht ontvangen.  Wil je graag antwoord van een bepaalde persoon?  Dan kan je die hieronder aanklikken. Het kan zijn dat door drukte of vakantie je toch antwoord van een andere persoon krijgt. ",
    "CONFIDANT_SELECT_NO_PREFERENCE": "Geen voorkeur"
  },
  "CHAT": {
    "CHAT_CONSENT": "Bel in geval van nood 112 of neem telefonisch contact op met het begeleidingsteam.",
    "I_UNDERSTAND": "Akkoord",
    "DECLINE": "Afwijzen",
    "CONSULTANT_TEAM": "Onze adviseurs",
    "CHAT_INTRO": "Stuur ons een bericht zodat we je kunnen ondersteunen met je verzoek.",
    "USER_HAS_UNSIGNED_CHATS": "Onze chat is momenteel niet live. Laat dan een bericht achter, onze adviseurs nemen binnen de volgende werkdag contact met je op. Neem in geval van nood telefonisch contact met ons op of bel 112.",
    "NOT_ASSIGNED": "Vertrouwenspersoon",
    "NEW_CHAT": "Nieuw gesprek",
    "INPUT_PLACEHOLDER": "Typ hier een bericht...",
    "INPUT_PLACEHOLDER_CONNECTING": "Een beveiligde verbinding opzetten...",
    "CURRENT_CHAT": "Huidig gesprek",
    "YOU_ARE_SPEAKING_WITH": "Je praat met"
  },
  "WORDS": {
    "REPORT": {
      "SCOLDING": "Schelden",
      "DISCRIMINATION": "Discriminatie",
      "THREAT": "Bedreiging",
      "ASSAULT": "Mishandeling",
      "SEXUAL_VIOLENCE": "Seksueel geweld",
      "SEXUAL_HARASSMENT": "Seksuele intimidatie",
      "STALKING": "Stalking",
      "BULLYING": "Pesten",
      "FRAUD": "Fraude",
      "CORRUPTION": "Corruptie",
      "BLACKMAILING": "Afpersing",
      "RACISM": "Racisme",
      "AGGRESSION": "Aggressie",
      "HUMILIATION": "Vernedering",
      "THEFT": "Diefstal",
      "INTIMIDATION": "Intimidatie",
      "PLAGIARISM": "Plagiaat"
    },
    "FEELINGS": {
      "SCARED": "Bang",
      "ANGRY": "Boos",
      "STRESSED": "Gestrest",
      "EMBARRASSED": "Beschaamd",
      "LONELY": "Eenzaam",
      "INSECURE": "Onzeker",
      "HUMILIATED": "Vernederd",
      "HURT": "Gekwetst",
      "OVERWHELMED": "Overweldigd",
      "CONFUSED": "Verward",
      "THREATENED": "Bedreigd",
      "UNSAFE": "Onveilig",
      "FRUSTRATED": "Gefrustreerd",
      "MISUNDERSTOOD": "Onbegrepen",
      "DESPERATE": "Wanhopig",
      "DEPRESSED": "Depressief",
      "SHOCKED": "Geschokt"
    }
  },
  "CONFIDANTS_INFO": {
    "MENU_ITEM": "Onze vertrouwenspersonen",
    "TITLE": "Vertrouwenspersonen",
    "MAKE_APPOINTMENT": "Afspraak maken met {{NAME}}",
    "MAKE_APPOINTMENT_TITLE": "Afspraak maken"
  },
  "MAKE_APPOINTMENT": {
    "SELECTED_CONFIDANT": "Geselecteerde vertrouwenspersoon",
    "LEAVE_YOUR_DETAILS": "Laat hieronder je gegevens achter bij ons vertrouwenscentrum en maak via de chat een afspraak.",
    "FIRST_NAME": "Voornaam",
    "LAST_NAME": "Achternaam",
    "EMAIL": "E-mailadres (privé)",
    "PHONE": "Telefoonnummer (privé)",
    "AGREEMENT": "Ik bevestig dat ik akkoord ga met de gebruiksvoorwaarden en het privacybeleid",
    "SEND": "Verstuur gegevens naar ons vertrouwenscentrum en maak afspraak via de chat"
  }
}
